import axios from "axios";


const remoteApiUrl = "https://dev-api.healthycitiesapp.com";

const axiosInstance = axios.create({
	baseURL: remoteApiUrl
});


axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

export default axiosInstance;
