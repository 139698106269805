import styles from './Footer.module.css'
import other_companies_logo from '../../../../assets/images/other-companies-logo.png'
//import farkadona_logo from '../../../../assets/images/farkadona.png'

export default function Footer() {
    return (
        <footer className={styles.footer}>
            <div className={styles.logo_wrapper} >
                <img alt='companies' height='150' src={other_companies_logo} />
                {/* <img alt='companies' height='79' src={farkadona_logo} /> */}
            </div>
        </footer>
    )
};