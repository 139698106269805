
import AppRoutes from "./appRoutes";
import styles from "./Layout.module.css";

export default function Layout() {

    return (
        <div className={`${styles.layout} `}>
            <AppRoutes />
        </div>
    )
};
