
import styles from "./Home.module.css"
import urban_logo from '../../../../assets/images/urban-planning-logo.png'
import health_logo from '../../../../assets/images/health-input-logo.png'
import Card from './Card.js';

export default function Home() {
    return (
        <div>
            <div className={styles.home}>
                <div style={{ fontSize: '30px', color: '#000' }}>Choose your approach</div>
                <div className={styles.home_body}>
                    <Card
                        title='Planning'
                        titleColor='#f7ba7d'
                        text={'Assess your urban planning actions to determine their health impact'}
                        action1='Start'
                        logo={urban_logo}
                        url='urban-planning-portal'
                    />
                    <Card
                        title='Health'
                        titleColor='#f7ba7d'
                        text={'Select your health goals to guide your urban planning strategy'}
                        action1='Start'
                        logo={health_logo}
                        url='health-planning-portal'
                    />
                </div>
                <div>
                    <span style={{ fontWeight: 500 }}>Disclaimer: </span>
                    <span style={{ fontStyle: 'italic' }}>
                        {`
                        This is a fully functional Beta version of the tool. The Healthy
                        Cities Generator is being continuously improved and an updated version of the tool will
                        soon be available.
                        `}
                    </span>
                </div>
            </div>
            <div className={styles.mobileIncompatible}>
                Sorry! Mobile devices are not supported in the current version of Healthy Cities Generator. Please use Desktop/Laptop preferably using Google Chrome browser.
            </div>
        </div >
    )
}

