import React, { useState } from 'react';
import styles from './Login.module.css'
import { useHistory } from 'react-router-dom';
import { ArrowSmRightIcon } from '@heroicons/react/outline'
import { Button, Checkbox, Form, Input, notification } from 'antd';
import API from '../../../../services/API.js'
import { endpoints } from '../../../../services/endpoints.js'

const openNotification = (type, title, msg) => {
    notification[type]({
        message: title,
        description:
            msg,
    });
};

const createURL = (domain) => {
    const myUrlWithParams = new URL(`https://${domain}.healthycitiesapp.com`);
    window.location.replace(myUrlWithParams.href);
}

export default function Login() {
    const [modeLogin, setModeLogin] = useState(true);
    const history = useHistory();

    const onRequestLogin = (values) => {
        let domain = values.projectID.toLowerCase()
        let url = `${endpoints.API_CHECK_CITY}/${domain}`
        API.get(url)
            .then(res => {
                if (res.data.isExists) {
                    if (res.data.role === 'user') {
                        createURL(domain)
                    } else {
                        openNotification('error', 'Project not found', 'City domain does not exist. Please make sure that the city you have entered is available.')
                    }
                } else {
                    openNotification('error', 'Project not found', 'City domain does not exist. Please make sure that the city you have entered is available.')
                }
            })
            .catch(err => {
                openNotification('error', 'Something went wrong', `An internal error occurred. If this error persists, please connect with the page administrator.`)
            })
    }


    const onRequestDemo = (values) => {
        API.post(endpoints.API_REQUEST_DEMO,
            {
                fullname: values.fullname.toLowerCase(),
                email: values.email.toLowerCase(),
                description: values.description.toLowerCase(),
                newsletter: values.newsletter,

            })
            .then(
                res => {
                    if (res.status === 200) {
                        openNotification('success', 'Request sent successfully', 'We will get back to you via email as soon as we process your request.')
                        history.push('/')
                    }
                }
            )
            .catch(
                err => {
                    openNotification('error', 'Something went wrong', `An internal error occurred. If this error persists, please connect with the page administrator.`)
                }
            )
    }

    return (
        <div className={styles.login}>
            <div className={styles.form}>
                <div className={styles.form_title}>Welcome to Health Cities Generator</div>
                <div className={styles.options_wrapper}>
                    <div className={styles.option} onClick={() => setModeLogin(true)} >My projects</div>
                    <div className={styles.option} onClick={() => setModeLogin(false)}>Request demo</div>
                    <div className={styles.underline} style={{ left: modeLogin ? '0%' : '50%' }} />
                </div>
                <div className={styles.form_wrapper}>
                    {modeLogin
                        ?
                        <React.Fragment>
                            <Form
                                preserve={false}
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                onFinish={onRequestLogin}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Project ID"
                                    name="projectID"
                                    rules={[{ required: true, message: 'Required field' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>

                            </Form>
                            <div>
                                <div>{`Don't have an account?`} <span className={styles.link} onClick={() => setModeLogin(false)}>Request demo  <ArrowSmRightIcon className={styles.link_icon} /></span></div>
                                <div onClick={() => history.push('/')}><span className={styles.link}>{`Back to homepage`} <ArrowSmRightIcon className={styles.link_icon} /></span></div>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Form
                                preserve={false}
                                name="basic"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onFinish={onRequestDemo}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Project ID"
                                    name="fullname"
                                    rules={[{ required: true, message: 'Field is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ type: 'email', required: true, message: 'Field is required / Incorrect format' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="City/Organization"
                                    name="description"
                                    rules={[{ required: true, message: 'Field is required' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item name="newsletter" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                                    <Checkbox>Sign up for our newsletter</Checkbox>
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div>
                                <div>{`Already have a project?`} <span className={styles.link} onClick={() => setModeLogin(true)}>Visit <ArrowSmRightIcon className={styles.link_icon} /></span></div>
                                <div onClick={() => history.push('/')}><span className={styles.link}>{`Back to homepage`} <ArrowSmRightIcon className={styles.link_icon} /></span></div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}