import { useHistory } from "react-router-dom";
import { Dropdown } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import styles from "./Header.module.css";
import nav_logo from '../../../../assets/images/nav_logo.jpg'

export default function HeaderComp() {
    const history = useHistory();

    const setWindowRef = (link) => {
        window.location.href = link
    }

    const menu = <div className={styles.mobileMenu}>
        <div className={styles.links} onClick={() => setWindowRef('https://healthycitiesgenerator.com/model/')} >Model </div>
        <div className={styles.links} onClick={() => setWindowRef('https://healthycitiesgenerator.com/resources/')} >Resources </div>
        <div className={styles.links} onClick={() => setWindowRef('https://healthycitiesgenerator.com/meet-the-team/')} >Get in touch </div>
    </div >

    return (
        <div className={styles["flex-container"]}>
            <div style={{ display: 'flex' }}>
                <div onClick={() => history.push('/')}>
                    <img alt='app-logo' className={styles.logo} height='100' src={nav_logo} />
                </div>
            </div>
            <div className={styles.header_links} >
                <a className={styles.links} href='https://healthycitiesgenerator.com/model/' rel="noreferrer" target="_blank">Model</a>
                <a className={styles.links} href='https://healthycitiesgenerator.com/resources/' rel="noreferrer" target="_blank">Resources</a>
                <a className={styles.links} href='https://healthycitiesgenerator.com/meet-the-team/' rel="noreferrer" target="_blank">Get in touch</a>
                <div className={styles.links} onClick={() => history.push('/login')}>Login</div>
            </div >
            <div className={styles.mobileBtn} onClick={e => e.preventDefault()}>
                <Dropdown overlay={menu} trigger={['click']}>
                    <MenuFoldOutlined />
                </Dropdown>
            </div>
        </div>
    )
}