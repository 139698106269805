import { Route, Switch } from "react-router-dom";
import Home from './main/home/index.js';
import Login from './main/login/index.js';
import Header from './main/header/index';
import Footer from '../Layout/main/footer/Footer.js';

export default function AppRoutes() {
    return (
        <Switch>
            <main>
                <Header />
                <Route path={"/"} exact render={() => <Home />} />
                <Route path={"/login"} exact render={() => <Login />} />
                <Footer />
            </main>
        </Switch>
    )
};
