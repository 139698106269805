import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";
import 'antd/dist/antd.css';

export default function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  );
}
