
import styles from "./Card.module.css"
import { useHistory } from "react-router-dom";

const Card = ({ title, logo, action1, titleColor, text }) => {
    const history = useHistory();
    const urlHandler = () => {
        history.push({ "pathname": `/login` })
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.logo_wrapper} >  <img alt='logo' height='150' src={logo} /></div>
            <div className={styles.app_title} style={{ color: titleColor }}>{title}</div>
            <div className={styles.card_text}>{text}</div>
            <button onClick={urlHandler} className={styles.link_to_app}>{action1}</button>
        </div>
    )
}

export default Card